.Nav {
    text-align: center;
}

.Nav-Box {
    width: 100vw;
    height: 75px;
    left: 0px;
    top: 0px;
    z-index: 0;
    background: linear-gradient(180deg, #000000 73.3%, rgba(0, 0, 0, 0) 100%);
}

.Nav-Text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    z-index: 1;
    margin-top: -65px;
}

.Nav-Button {
    font-family: "typeface-copse", serif;
    font-size: larger;
    font-min-size: small;
    color: white;
    background-color: black;

}