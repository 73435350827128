.Profile-Image {
    display: flex;
    align-content: center;
    margin-top: 30px;
    width: 400px;
    height: 400px;
}

.Name-Description-Cont {
    font-family: "typeface-copse", serif;
    color: #DAD398;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 80vw;
}

.Name {
    font-size: 45pt;
    letter-spacing: 7px;
}

.Description {
    font-size: 17pt;
}