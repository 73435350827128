.Layout {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 0px;
    flex-wrap: wrap;
    width: 70vw;
    margin-top: 70px;
    margin-bottom: 70px;
}

.Button {

}