.Name-Description-Cont {
    font-family: "typeface-copse", serif;
    color: #DAD398;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 90%;
    margin-top: 30px;
    text-align: center;
}

.Name {
    font-size: 45pt;
    letter-spacing: 7px;
}

.Description {
    font-size: 13pt;
}